import '../styles/index.css'

const ageGate = document.querySelector('.age-gate')
const ageEnter = document.querySelectorAll('.age-enter')
const ageExit = document.querySelector('.age-exit')

if (ageGate && ageEnter.length) {
  ageEnter.forEach((el) => {
    el.addEventListener('click', () => {
      ageGate.classList.add('is-removing')
    })
  })
}

if (ageExit) {
  ageExit.addEventListener('click', () => {
    window.location.href = 'https://google.com'
  })
}

const headlineOuter = document.querySelector('.headline')
const headlineInner = document.querySelector('.headline div')
const content = document.querySelector('.main-inner')

const handleContentPosition = () => {
  const headlineOuterLeft = headlineOuter.getBoundingClientRect().left
  const headlineInnerLeft = headlineInner.getBoundingClientRect().left

  if (window.matchMedia('(min-width: 768px)').matches) {
    content.style.left = `${headlineInnerLeft - headlineOuterLeft}px`
  } else {
    content.style.left = null
  }
}

handleContentPosition()

window.addEventListener('resize', handleContentPosition)
